import React from "react";
import Button from "../components/Button";
import Card from "../components/Card";
import Layout from "../components/layout";
import SEO from "../components/seo";

function Contato() {
  return (
    <Layout>
      <SEO title="AtendeJá" />
      <div className="text-center mx-5 md:mx-24 xl:mx-64 my-24">
        <h1 className="font-bold md:mt-24">
          Fale conosco e tire as suas dúvidas
        </h1>
        <h3 className="text-gray my-8">
          Quer falar com a gente? Gostaria de tirar alguma dúvida? Veja como
          entrar em contato.
        </h3>
      </div>

      <div className="grid lg:grid-cols-3 mx-5 md:mx-16 lg:mx-0 xl:mx-40 pb-10">
        <Card
          className="flex col-span-1 lg:col-start-2"
          icon="support"
          title="Precisa de atendimento urgente?"
          description="Se você é nosso cliente e precisa de atendimento, utilize a área do cliente para abrir um chamado."
        >
          <div className="text-center">
            <Button className="mt-6">ABRIR CHAMADO DO SUPORTE</Button>
          </div>
        </Card>
        <Card
          className="hidden col-span-1 xl:col-start-3"
          icon="book"
          title="O que você gostaria de aprender?"
          description="Encontre guias rápidos e respostas para perguntas frequentes dentro de cada categoria."
        >
          <div className="text-center">
            <Button className="mt-6">ACESSAR CENTRAL DE AJUDA</Button>
          </div>
        </Card>
      </div>

      <div className="background-white">
        <h1 className="text-center font-bold mx-5 xl:mx-64 pt-20 md:mt-24">
          Venha nos visitar ou entre em contato
        </h1>

        <div className="lg:flex py-24 mx-10">
          <div className="w-full lg:w-3/5">
            <iframe
              width="100%"
              title="SoftinLocationMap"
              height="360"
              frameBorder="0"
              style={{ border: 0, display: "block" }}
              src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJeWIh6ymu3pQRQn5TuelrGTQ&amp;key=AIzaSyDO25n3yXP5OUj8q1jNnj7p4WlnP-8B1n4"
              allowFullScreen=""
            />
          </div>
          <div className="ml-5 mt-5 lg:mt-0 lg:w-1/2 xl:w-1/4">
            <h2 className="font-bold">Localização</h2>
            <p className="text-gray mt-1">
              Rua Iririú, 847 - Saguaçu - Joinville, Santa Catarina - Brasil
            </p>

            <h2 className="font-bold mt-8">Contato</h2>
            <p className="text-gray mt-1">(47) 3437-3312</p>
            <p className="text-gray mt-1">(47) 99134-2776</p>
            <p className="text-gray mt-1">comercial@atendeja.com.br</p>

            <h3 className="font-bold mt-8">
              Fale com nossa equipe de segunda a sexta-feira, das 8h às 18h.
            </h3>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Contato;
