typeof window !== "undefined" ? require("boxicons") : null;
import PropTypes from "prop-types";
import React from "react";

const Card = ({ className, discount, icon, title, description, children }) => {
  return (
    <div className={`${className}`}>
      {discount && (
        <div className="card-extra text-center">12% DE DESCONTO</div>
      )}
      <div className={`card-container ${!discount && "mt-8"}`}>
        <box-icon name={icon} color="#35B554" size="lg" />
        <h2 className="my-3 font-bold">{title}</h2>
        <p className="text-gray mb-5">{description}</p>
        {children}
      </div>
    </div>
  );
};

Card.propTypes = {
  discount: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
  description: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string,
};

export default Card;
